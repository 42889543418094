.s-container{
    position: relative;
    margin-bottom: 5rem;

}

.s-container .swiper{
    width: 70%;
    height: 12rem;
    position: unset;
}

.s-container .swiper-slide{
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev{
    left:6rem;
}

.s-container .swiper-button-prev .s-container .swiper-button-next{
    color: black;
}

